import React, { Component } from "react";
import Helmet from "react-helmet";
import Layout from "../layout";
import config from "../../data/SiteConfig";
import AvatarLinks from "../components/Avatar/AvatarLinks";

/** @jsx jsx */
import { Styled, jsx } from "theme-ui";

class Research extends Component {
  render() {
    return (
      <Layout>
        <Helmet title={`${config.siteTitle} | Research`} />
        <div sx={{ marginTop: `120px`, marginBottom: `30px` }}>
          <Styled.p sx={{ textAlign: `center`, color: `primary`}}>
            The research page will be completed soon. Please check out my work via the following in the meanwhile.
          </Styled.p>

          <AvatarLinks />
        </div>
      </Layout>

    );
  }
}

export default Research;
